import * as firebase from 'firebase'

const config = {
    apiKey: "AIzaSyCqZQSBiDxw2LX2_196FldGJS_s6A2eUk4",
    authDomain: "rankedjs.firebaseapp.com",
    databaseURL: "https://rankedjs.firebaseio.com/",
    projectId: "rankedjs",
    storageBucket: "rankedjs.appspot.com",
}

firebase.initializeApp(config)

const database = firebase.database()

export { database as default }